<template>
  <b-card-code title="Créer un nouveau Année universitaire">
    <b-card-text>
      <span>Veuillez saisir les coordonnées de l'Année universitaire </span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Année"
              label-for="Année"
            >
              <validation-provider
                #default="{ errors }"
                name="Année"
                rules="required"
              >
                <b-form-input
                  v-model="academicYear.academic_year"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Année"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <b-col md="6">
            <b-form-group
              label="Etat"
              label-for="Etat"
            >
              <validation-provider
                #default="{ errors }"
                name="state"
                rules="required"
              >
                <v-select
                  v-model="academicYear.state"
                  :clearable="false"
                  :options="states"
                  placeholder="Statut de l'année"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <b-col md="6">
            <label for="example-datepicker">Date début de l'année</label>
            <flat-pickr
              v-model="academicYear.start_date"
              class="form-control invoice-edit-input mb-1"
            />
          </b-col>
          <b-col md="6">
            <label for="example-datepicker">Date fin de l'année</label>
            <flat-pickr
              v-model="academicYear.end_date"
              class="form-control invoice-edit-input mb-1"
            />
          </b-col>
          <b-col md="6">
            <label for="example-datepicker">Date fin de semester 1</label>
            <flat-pickr
              v-model="academicYear.end_date_semester_1"
              class="form-control invoice-edit-input mb-1"
            />
          </b-col>
          <b-col md="6">
            <label for="example-datepicker">Date Debut de semester 2</label>
            <flat-pickr
              v-model="academicYear.start_date_semester_2"
              class="form-control invoice-edit-input mb-1"
            />
          </b-col>

          <b-col md="6">
            <b-col cols="12">
              <b-button
                variant="primary"
                type="submit"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                @click.prevent="addAcademicYear"
              >
                Ajouter
              </b-button>
              <b-button
                variant="outline-secondary"
                type="reset"
              >
                Réinitialiser
              </b-button>
            </b-col>
          </b-col></b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import storeAuth from '@/store/store'

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText /*  */,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    flatPickr,

  },
  data() {
    return {
      password: '',
      academicYear: {
        academic_year: '',
        state: 'Ouverte',
        start_date: '',
        end_date: '',
        end_date_semester_1: '',
        start_date_semester_2: '',
        principal_deliberation: false,
        control_deliberation: false,
      },
      required,
      // states: ['Ouverte', 'Clôturée'],
    }
  },

  methods: {
    async addAcademicYear() {
      this.$swal({
        showCancelButton: true,
        title: 'vous êtes sûr ! ',
        icon: 'warning',
        text: "vous êtes sûr de cloturer l'année dernière et ouvrir l'année suivante ?",
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async () => {
        this.$refs.simpleRules.validate().then(async success => {
          if (success) {
            try {
              const { data } = await axios
                .post(
                  '/api/academic-years/create/',
                  {
                    academic_year: this.academicYear.academic_year,
                    state: this.academicYear.state,
                    start_date: this.academicYear.start_date,
                    end_date_semester_1: this.academicYear.end_date_semester_1,
                    start_date_semester_2: this.academicYear.start_date_semester_2,
                    end_date: this.academicYear.end_date,
                  },
                )
              storeAuth.commit('setCurrentAcademicYear', data)

              this.$router.push('/academic-years')
              setTimeout(() => {
                this.showToast('success', 'top-center', 'Année universitaire ajouté avec succés')
              }, 1000)
            } catch (err) {
              setTimeout(() => {
                this.showToast('danger', 'top-center', err.toString())
              }, 1000)
            }
          }
        })
      })
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
